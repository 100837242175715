<template>
  <base-virtual-list v-if="!loading" :items="items">
    <template v-slot:item="{ item }">
      <object-list-item
        :item="item"
        :actions="getItemActions(item)"
        :to="getItemRoute(item)"
      />
    </template>
  </base-virtual-list>
</template>

<script>
import ObjectListItem from './ObjectListItem';
import { inject } from '@vue/composition-api';
import { useObjectMenu } from '@/modules/objects/compositions/objectMenu';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';

export default {
  name: 'LandmarkCardObjects',
  components: {
    ObjectListItem
  },
  setup() {
    const objectList = inject('landmarkObjects');
    const loading = inject('loadingLandmarkObjects');

    const { getNextRoute } = useHistoryStack();

    const getItemRoute = item =>
      getNextRoute({
        component: componentAliases.oc,
        props: {
          objectId: item.id
        }
      });

    const getItemActions = object => {
      return useObjectMenu({ object });
    };

    return {
      items: objectList,
      loading,
      getItemRoute,
      getItemActions
    };
  }
};
</script>

<style></style>
